/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { Box } from "@theme-ui/components"
import ExplicitContent from "./tag-explicit"
import ItemTags from "./item-tags"

type PostTypeProps = {
  timeToRead: number
  explicit: boolean
  postDate: string
  postTags: {
    name: string
    slug: string
  }[]
}

const PostProperties = ({ timeToRead, explicit, postDate, postTags }: PostTypeProps) => (
  <Box sx={{ padding: `0 60px 0 60px`, width: `80vw` }}>
    <Box
      sx={{ fontWeight: `800`, fontSize: `0.8rem`, fontFamily: `Montserrat`, lineHeight: `1rem`, color: `#2A2D34`, letterSpacing: `2.4px`, textTransform: `Uppercase`, }}
    >
      <ItemTags tags={postTags} /> / {postDate} / {timeToRead} min read
      {explicit === true && (` / `) }
      {explicit === true && 
        <ExplicitContent />
      }
    </Box>
  </Box>
)

export default PostProperties
