/** @jsx jsx */
import { jsx } from "theme-ui"
import { Flex } from "@theme-ui/components"
import useMinimalBlogConfig from "../hooks/use-minimal-blog-config"
import Navigation from "./navigation"
import HeaderTitle from "./header-title"
import ExternalLinksIcons from "./external-links"
import DarkBox from "./dark-box"
import HeaderBox from "./header-box"
import Title from "./title"
import PostProperties from "./tag-post-properties"

type TitleProps = {
  text: string
  bgImg: string
  timeToRead: number
  postDate: string
  postTags: {
    name: string
    slug: string
  }[]
}

const PostHeader = ({ text, bgImg, timeToRead, postDate, postTags }: TitleProps) => {
  const { navigation: nav } = useMinimalBlogConfig()

  return (
    <HeaderBox sx={{backgroundImage: `url(` + bgImg + `)`, backgroundSize: `cover`}}>
      <DarkBox />
      <Flex sx={{
        alignItems: `top`,
        justifyContent: `space-between`,
        height: `100%`,
        width: `100%`,
        margin: `0`,
        padding: `0 70px 0 70px`,
        borderLeft: `30px solid #2a2d34`,
        borderRight: `30px solid #2a2d34`,
        flexDirection: [`row`]
      }}
      >
        <HeaderTitle />
        <Flex sx={{
          boxSizing: `border-box`,
          display: `flex`,
          alignItems: `top`,
          justifyContent: `space-between`,
          mt: 3,
          mb: 3,
          color: `primary`,
          a: { color: `#2a2d34`, ":hover": { color: `#f4e409` } },
          flexFlow: `wrap`,
          fontFamily: `Montserrat`,
          fontSize: `12px`,
          textTransform: `Uppercase`,
        }}
        >
        <Navigation nav={nav} />
        <ExternalLinksIcons />
        </Flex>
      </Flex>
        
      <Flex sx={{ alignItems: `bottom`, borderLeft: `30px solid #2a2d34`, borderRight: `30px solid #2a2d34`, padding: `120px 0 30px 0`}}>
        <Title text={text} />
      </Flex>

      <Flex sx={{ alignItems: `bottom`, borderLeft: `30px solid #2a2d34`, borderRight: `30px solid #2a2d34`, padding: `0 0 100px 0`}}>
        <PostProperties timeToRead={timeToRead} postDate={postDate} postTags={postTags} explicit={false} />
      </Flex>

      <DarkBox />
    </HeaderBox>
  )
}

export default PostHeader
