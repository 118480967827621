/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box, Text, Flex } from "@theme-ui/components"
import SectionBox from "./section-box"

const Author = () => {

  return (
    <SectionBox
      sx={{
        boxSizing: `border-box`,
        display: `flex`,
        color: `#2A2D34`,
        a: {
          color: `#2A2D34`,
        },
        minHeight: `400px`,
        backgroundColor: `#7D8CA3`,
        padding: `60px`
      }}
    >
      <Box sx={{ width: `280px`, height: `280px`, backgroundImage: `url('./author-peter.png')`, backgroundSize: `cover`, display: `inline-block`, verticalAlign: `text-top` }} />
      <Box sx={{ display: `inline-block`, paddingLeft: `60px`, paddingRight: `60px`, verticalAlign: `text-top`, width: `80%`}}>
        <Text className="author-heading">Author</Text>
        <Text className="author-name">Peter “Raavn” Kisel</Text>
        <Text className="author-description">I started this blog to give musicians (but not just them) an outlet of information that is actionable, valuable, or funny at least.
          So that we can finally draw a fine line between bullshit advice from “business model influencer coaches”. 
          To be very honest: I’m an attention whore - but with good intentions. My purpose in life is to save people from themselves.
          For all the “omg, credentials!” people: I am a music lead at Burning Man events, I run Dark Beauty, I mentored DJs who play Awakenings now, and I’m an involuntary comedian.</Text>
      </Box>
    </SectionBox>
  )
}

export default Author
