/** @jsx jsx */
import { jsx } from "theme-ui"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Text } from "@theme-ui/components"
import React from "react"
import Layout from "./layout"
import SEO from "./seo"
import PostHeader from "./header-post"
import SectionBox from "./section-box"
import Author from "./author"

type PostProps = {
  data: {
    post: {
      slug: string
      title: string
      date: string
      tags?: {
        name: string
        slug: string
      }[]
      description?: string
      body: string
      excerpt: string
      timeToRead?: number
      banner?: {
        childImageSharp: {
          resize: {
            src: string
          }
        }
      }
    }
  }
}

const Post = ({ data: { post } }: PostProps) => (
  <Layout>
    <SEO
      title={post.title}
      description={post.description ? post.description : post.excerpt}
      image={post.banner ? post.banner.childImageSharp.resize.src : undefined}
      pathname={post.slug}
    />
    <PostHeader
      text={post.title}
      bgImg={post.banner ? post.banner.childImageSharp.resize.src : `./post-default.jpg`}
      timeToRead={post.timeToRead}
      postDate={post.date}
      postTags={post.tags}
    />
    <SectionBox 
      sx={{
        width: `60vw`,
        minWidth: `960px`,
        margin: `60px auto`,
        color: `#808080`,
        fontFamily: `Open Sans`,
        fontWeight: `300`,
        lineHeight: `2.5rem`,
        a: {
          color: `#7D8CA3`,
          textDecoration: `underline`,
          fontWeight: `400`,
          ":hover": {
            color: `#f4e409`,
          },
        },
        strong: {
          fontWeight: `400`,
        },
        p: {
          lineHeight: `2.5rem`,
          paddingBottom: `1.2rem`,
        },
        h3: {
          fontFamily: `Montserrat`,
          fontSize: `1.6em`,
          lineHeight: `1.6em`,
          textTransform: `uppercase`,
          letterSpacing: `4.3px`,
          paddingTop: `1.5rem`,
          paddingBottom: `0.5rem`,
        },
      }}
    >
      <MDXRenderer>{post.body}</MDXRenderer>
    </SectionBox>

  {/* Author info */}
    <SectionBox>
      <Author />
    </SectionBox>
  </Layout>
)

export default Post
